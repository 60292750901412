<template>
  <div class="ddj-offer">
    <!-- <div class="main-Title bgff"><h2>Task补量</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="list-filter mb10">
        <el-form :model="filter" label-width="80px" size="small">
          <el-row>
            <el-col :xs="24" :sm="12" :md="5" :xl="4">
              <el-form-item label="offer ID:"
                ><el-input
                  v-model="filter.offerId"
                  placeholder="Please enter offer ID"
                  class="mr10"
                ></el-input
              ></el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="5" :xl="4">
              <el-form-item label="Country:">
                <el-select v-model="filter.country" placeholder="Please select" class="w100">
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.country"
                    :key="item.countryCode"
                    :value="item.countryCode"
                    :label="item.countryCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="5" :xl="3">
              <el-form-item label="OS:" label-width="50px">
                <el-select v-model="filter.os" placeholder="Please select OS" class="w100">
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.os"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="5" :xl="4">
              <el-form-item label="上游名称:">
                <el-select
                  v-model="filter.advertiserName"
                  placeholder="Please select Advertiser"
                  class="w100"
                >
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.advertiser"
                    :key="item.advertiserName"
                    :value="item.advertiserName"
                    :label="item.advertiserName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="4" :xl="2">
              <el-form-item label-width="0px">
                <el-button
                  type="primary"
                  size="mini"
                  :loading="loading.list"
                  @click="getList('filter')"
                  >Search</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          class="w100"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="offer ID" prop="offerId"></el-table-column>
          <el-table-column label="offer Name" prop="title"></el-table-column>
          <el-table-column label="Country" prop="country"></el-table-column>
          <el-table-column
            label="offer URL"
            prop="trackingLink"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="OS" prop="os"></el-table-column>
          <el-table-column label="数据包" prop="dataSourceName"></el-table-column>
          <el-table-column label="Price" prop="price"></el-table-column>
          <el-table-column label="点击CAP" prop="clickNumber"></el-table-column>
          <el-table-column label="OF" prop="emuName"></el-table-column>
          <el-table-column label="转化率" prop="convRate">
            <template slot-scope="scope">
              {{ scope.row.convRate + '%' }}
            </template>
          </el-table-column>
          <el-table-column label="成功点击数" prop="sendSuccessCount"></el-table-column>

          <el-table-column label="送量状态" prop="sendStatus" align="center">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.sendStatus == 1 ? 'success' : 'danger'"
                effect="dark"
                size="mini"
                >{{
                  scope.row.sendStatus == 1
                    ? 'running'
                    : scope.row.sendStatus == 2
                    ? 'Exception'
                    : 'paused'
                }}</el-tag
              >
            </template>
          </el-table-column>
          <!--   <el-table-column label="状态" prop="status" align="center">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.status == 1 ? 'success' : 'danger'"
                effect="dark"
                size="mini"
                >{{ scope.row.status == 1 ? '开启' : '关闭' }}</el-tag
              >
            </template>
          </el-table-column> -->
          <el-table-column label="上游名称" prop="advertiserName"></el-table-column>
          <el-table-column label="添加时间" prop="createTime"></el-table-column>
          <el-table-column label="操作" width="150" fixed="right" align="center">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.message || '开启'"
                placement="top"
              >
                <el-button
                  type="success"
                  icon="el-icon-video-pause"
                  circle
                  size="mini"
                  v-loading="loading.startBtn"
                  @click="start(scope.row.id)"
                ></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="暂停" placement="top">
                <el-button
                  type="danger"
                  icon="el-icon-video-play"
                  circle
                  size="mini"
                  v-loading="loading.stopBtn"
                  @click="stop(scope.row.id)"
                ></el-button>
              </el-tooltip>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="del(scope.row.id)"
              ></el-button>
              <!-- <el-button
                type="primary"
                icon="el-icon-more"
                circle
                size="mini"
                @click="more(scope.row.offerId, scope.row.advertiserCode)"
              ></el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
      <el-dialog title="子渠道配置" :visible.sync="diallog.more">
        <el-form ref="SubAffData" :model="SubAffData" :rules="rules" label-width="150px">
          <el-form-item label="offer ID" prop="offerId"
            ><el-input v-model="SubAffData.offerId" placeholder="请输入offer ID" :disabled="true"
          /></el-form-item>
          <el-form-item label="Sub_aff" prop="subAff">
            <el-button type="primary" size="mini" @click="randomFun()">随机生成</el-button>
            <div class="random">{{ random }}</div>
            <el-input
              type="textarea"
              placeholder="请输入subAff"
              :autosize="{ minRows: 5, maxRows: 10 }"
              v-model="SubAffData.subAff"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="diallog.more = false">取 消</el-button>
          <el-button type="primary" @click="submitSubAff()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import ddjTaskCon from '../../../controllers/DDJ/v3/task/supplement';
  export default {
    name: 'ddjSupplementTask',
    ...ddjTaskCon,
  };
</script>

<style></style>
