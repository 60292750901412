import request from 'utils/request';
const api = {
  TASK_PAGE: '/api/admin/v3/task/page',
  TASK_DETAILS: '/api/admin/v3/task/get',
  TASK_UPDATE: '/api/admin/v3/task/update',
  TASK_ADD: '/api/admin/v3/task/add',
  TASK_DEL: '/api/admin/v3/task/delete',
  TASK_START: '/api/admin/v3/task/read/start',
  TASK_STOP: '/api/admin/v3/task/read/stop',
  TASK_SUBAFF: '/api/admin/v3/offer/subaff/get',
  TASK_SUPPLEMENT_PAGE: '/api/admin/v3/task/supplement/page',
  TASK_SUPPLEMENT_START: '/api/admin/v3/task/supplement/read/start',
  TASK_SUPPLEMENT_STOP: '/api/admin/v3/task/supplement/read/stop',
  TASK_SUPPLEMENT_DEL: '/api/admin/v3/task/supplement/delete',
  TASK_MIX_CLICK_COUNT: '/api/admin/v3/task/get/mix/click/count',
};

// 分页
export function fetchTaskPage(query) {
  return request({
    url: api.TASK_PAGE,
    method: 'get',
    params: query,
  });
}

// 详情
export function fetchTaskDetails(query) {
  return request({
    url: api.TASK_DETAILS,
    method: 'get',
    params: query,
  });
}

// 更新
export function handleTaskUpdate(data) {
  return request({
    url: api.TASK_UPDATE,
    method: 'post',
    data,
  });
}

// 添加
export function handleTaskAdd(data) {
  return request({
    url: api.TASK_ADD,
    method: 'post',
    data,
  });
}

// 删除
export function fetchTaskDel(query) {
  return request({
    url: api.TASK_DEL,
    method: 'get',
    params: query,
  });
}

// 开启任务
export function fetchTaskStart(query) {
  return request({
    url: api.TASK_START,
    method: 'get',
    params: query,
  });
}

// 停止任务
export function fetchTaskStop(query) {
  return request({
    url: api.TASK_STOP,
    method: 'get',
    params: query,
  });
}

// 获取sub aff
export function fetchSubAff(query) {
  return request({
    url: api.TASK_SUBAFF,
    method: 'get',
    params: query,
  });
}

// 补量分页
export function fetchTaskSupplementPage(query) {
  return request({
    url: api.TASK_SUPPLEMENT_PAGE,
    method: 'get',
    params: query,
  });
}

// 补量删除
export function fetchTaskSupplementDel(query) {
  return request({
    url: api.TASK_SUPPLEMENT_DEL,
    method: 'get',
    params: query,
  });
}

// 补量开启任务
export function fetchTaskSupplementStart(query) {
  return request({
    url: api.TASK_SUPPLEMENT_START,
    method: 'get',
    params: query,
  });
}

// 补量停止任务
export function fetchTaskSupplementStop(query) {
  return request({
    url: api.TASK_SUPPLEMENT_STOP,
    method: 'get',
    params: query,
  });
}
// offer点击数
export function fetchTaskMixClickCount(query) {
  return request({
    url: api.TASK_MIX_CLICK_COUNT + '?offerId=' + query,
    method: 'get',
  });
}
